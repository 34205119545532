import { BaseService } from '../../base';
import { CreatorService } from '.';
import {
  Customer,
  EmailText,
  Invoice,
  InvoiceReceiver,
  InvoiceTextEmail,
  InvoiceVAT,
  LedgerNumber,
  Product,
  Profile,
} from '@/models';

import { ExternalInit, UntillItem } from '@/models/external/ExternalInit';

export default class UntillCreatorService
  extends BaseService
  implements CreatorService
{
  public data: ExternalInit<UntillItem>;
  private receipt: string;

  private get urlParams() {
    return {
      receipt: this.receipt,
    };
  }

  public async getReceipts(): Promise<{ [id: string]: string }> {
    const { data } = await this.axios().get(`/external/untill/pick`);

    return data.data;
  }

  public async setup(receipt: string): Promise<void> {
    const { data } = await this.axios().get(`/external/untill/init`, {
      params: {
        receipt,
      },
    });

    this.receipt = receipt;

    this.data = ExternalInit.deserialize(data.data);
  }

  public async getLogo(logo: string): Promise<any> {
    const { data } = await this.axios().get(`/external/untill/logo/${logo}`, {
      params: {
        ...this.urlParams,
      },
    });
    return data.data;
  }

  public async listReceivers(): Promise<InvoiceReceiver[]> {
    return this.data.customers.options.map((e) => e.toInvoiceReceiver());
  }

  public async listProfiles(): Promise<Profile[]> {
    return this.data.profiles.options;
  }

  public async searchProducts(keyword: string): Promise<Product[]> {
    void keyword;
    return [];
  }

  public async listLedgerNumbers(): Promise<LedgerNumber[]> {
    return this.data.ledger.options;
  }

  public async listVatCodes(): Promise<InvoiceVAT[]> {
    return this.data.vat.options;
  }

  public async emailText(emailTextId: number): Promise<InvoiceTextEmail> {
    const text = this.data.emailText.options.find(
      (e: EmailText) => +e.id === +emailTextId,
    );

    if (!text) {
      throw new Error('Emailtext not found');
    }

    return InvoiceTextEmail.fromProfileEmailText(text);
  }

  public async createInvoice(invoice: Invoice): Promise<string> {
    const { data } = await this.axios().post(
      `/external/untill`,
      invoice.serialize(),
      {
        params: {
          ...this.urlParams,
        },
      },
    );
    return data.data;
  }

  public async sendMail(id: string, text: InvoiceTextEmail): Promise<void> {
    await this.axios().post(
      `/external/untill/${id}/send/mail`,
      text.serialize(),
      {
        params: {
          ...this.urlParams,
        },
      },
    );
  }

  public async createCustomer(customer: Customer): Promise<number> {
    const { data } = await this.axios().post(
      `/external/untill/customer`,
      customer.serialize(),
      {
        params: {
          ...this.urlParams,
        },
      },
    );
    return data.data.id;
  }

  public async updateCustomer(customer: Customer): Promise<void> {
    await this.axios().put(
      `/external/untill/customer/${customer.id}`,
      customer.serialize(),
      {
        params: {
          ...this.urlParams,
        },
      },
    );
  }
}
